
















































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class DeviceDetails extends Vue {
  loading: boolean = false;
  id!: any;
  deviceData: any = null;
  merchantRoles: any[] = [];
  pagerHeader: any = {
    title: "设备详细",
    desc: "设备详细",
    breadcrumb: ["设备管理", "设备详细"]
  };
  async initPage() {
    this.id = this.$route.query.id;
    this.loading = true;
    try {
      var res = await this.$ajax.post("/api/services/app/Device/QueryDetail", {
        id: this.id
      });
      this.deviceData = res.data.result;
    } catch (error) {}
    this.loading = false;
  }
  created() {
    this.initPage();
  }
  activated() {
    if (this.id != this.$route.query.id) {
      this.merchantRoles = [];
      this.initPage();
    }
  }
    //   获取版本号
   async gainVsersion(id:any,type:any){
       let url=''
       if(type==1){  //库存
            url='/api/services/app/Device/UpdateStockInfo'
       }else if(type==2){  //网络
           url="/api/services/app/Device/UpdateNetwork"
       }else if(type==3){ //iccid
            url="/api/services/app/Device/UpdateICCID"
       }else if(type==4){ //版本
            url='/api/services/app/Device/UpdateVersion'
       }
       var loading = this.$loading({
        text: "获取中..."
      });
        try{
           var ress= await this.$ajax.put(
                url,
                {
                    id
                }
            )
            if(ress.data.success){
                this.$message.success("获取成功");
                this.initPage();
            }
        }catch{}
        loading.close();
    }
  async takeSlotNo(slotNo: number) {
    var result = await this.$confirm(`确认弹出${slotNo}卡位吗`);
    if (result == "confirm") {
      var loading = this.$loading({
        text: "弹出中..."
      });
      try {
        var res = await this.$ajax.post(
          "/api/services/app/ChargingBattery/Eject",
          {
            slot: slotNo,
            id: this.id
          }
        );
        if (res.data.success) {
          this.$message.success("弹出成功");
          this.initPage();
        }
      } catch (error) {}
      loading.close();
    }
  }
}
